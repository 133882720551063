// We need to include ie11 polyfills used by webpack dynamic import
// because webpack generated code does not go through babel
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
// import Bricks from 'bricks.js'
import SmoothScroll from 'smooth-scroll';
import gmapsInit from './utils/gmaps.js';
import 'whatwg-fetch';
import { mapStyles } from './utils/gmapsSettings.js';
// import Bricks

// console.log(window.sizes);
// // create an instance
// const bricks = Bricks({
//     container: '.bricks',
//     sizes: window.sizes,
// });

/**
 * Bugglyfill mobile safari navbar-hacks
 */

const hacks = require('viewport-units-buggyfill/viewport-units-buggyfill.hacks');
require('viewport-units-buggyfill').init({
    hacks,
});

// eslint-disable-next-line no-unused-vars,no-undef
const scroll = new SmoothScroll('a[href*="#"]');

async function initMaps() {
    try {
        const google = await gmapsInit(
            'AIzaSyASsjF2lmO48d4dcD_MHhQBomvf3JdsdxE',
        );
        const myLatLng = { lat: 47.18480678855903, lng: 8.937881621044246 };
        // eslint-disable-next-line no-unused-vars
        const map = new google.maps.Map(document.getElementById('map'), {
            center: new google.maps.LatLng(
                47.18480678855903,
                8.937881621044246,
            ),
            maxZoom: 24,
            minZoom: 1,
            zoom: 16,
            disableDefaultUI: true,
            zoomControl: false,
            scrollwheel: false,
            streetViewControl: false,
            mapTypeControl: false,
            styles: mapStyles,
        });
        const marker = new google.maps.Marker({
            position: myLatLng,
            map,
            label: {
                text: 'Zweifel Gartenbau',
                color: 'white',
                fontWeight: 'medium',
                fontSize: '16px',
            },
            icon: {
                labelOrigin: new google.maps.Point(30, 80),
                url: '/dist/img/gmap/pin.svg',
            },
        });
        // eslint-disable-next-line func-names
        google.maps.event.addListener(marker, 'click', function () {
            window.open('https://goo.gl/maps/iqCVJGyuXQ69bCkz8', '_blank');
        });
    } catch (error) {
        console.error(error);
    }
}

// App main
const main = async () => {
    // Async load LazySizes and it's plugins
    const LazySizes = await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/respimg/ls.respimg.js'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/parent-fit/ls.parent-fit.js'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/object-fit/ls.object-fit.js'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/blur-up/ls.blur-up.js'
    );

    // fix issue when image is already in viewport and content is not loaded yet
    document.addEventListener('DOMContentLoaded', function () {
        LazySizes.init();

    });
};

// Execute async function
main().then(() => {
    initMaps();


    // oldBrowserWarning
    // import(
    //    /* webpackChunkName: "oldBrowserWarning" */ './modules/oldBrowserWarning'
    // ).then(module => module.default.init());

    // load slider
    if (document.getElementsByClassName('js-slider').length) {
        import(/* webpackChunkName: "glide" */ './modules/glide.js')
            .then(glide => glide.slider.init())
            .catch(e => console.error(`${e.name} : ${e.message}`));
    }

    // load slider
    if (document.getElementsByClassName('js-carousel').length) {
        import(/* webpackChunkName: "glide" */ './modules/glide.js')
            .then(glide => glide.carousel.init())
            .catch(e => console.error(`${e.name} : ${e.message}`));
    }

    // gLightBox
    const gLightBox = document.getElementsByClassName('glightbox');
    if (gLightBox.length) {
        import(/* webpackChunkName: "gLightBox" */ './modules/glightbox');
    }

    // headroom
    const headroom = document.querySelector('.js-nav');
    if (headroom) {
        import(/* webpackChunkName: "headroom" */ './modules/headroom')
            .then(module => module.default.init())
            .catch(e => console.error(`${e.name} : ${e.message}`));
    }

    // Poolkonfigurator
    if (document.getElementsByClassName('js-pool').length) {
        import(
            /* webpackChunkName: "pool" */'./alpineJs/pool'
        );
    }
    // Forms
    if (document.getElementsByClassName('js-form').length) {
        import(
            /* webpackChunkName: "form" */'./alpineJs/form'
        );
    }
    // Forms
    if (document.getElementsByClassName('js-hiddenForm').length) {
        import(
            /* webpackChunkName: "hiddenForm" */'./alpineJs/hiddenForm'
        );
    }


    // load gallery
    // if (document.getElementsByClassName('js-gallery').length) {
    //     import(/* webpackChunkName: "photoswipe" */ './modules/photoswipe.js')
    //         .then(photoswipe => photoswipe.default.init('.js-gallery'))
    //         .catch(e => console.error(`${e.name} : ${e.message}`));
    // }
});

// accept HMR in dev
if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
        module.hot.accept();
    }
}
